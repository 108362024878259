import moment from 'moment';
import getOrderLabel from '@src/templates/dymo/order-label';
import getOrderBarcodeLabel from '@src/templates/dymo/order-barcode-label';
import getFdaLabel from '@src/templates/dymo/fda-label';

export default {
    async created() {
        this.addDymoScriptToHead();
    },

    methods: {
        addDymoScriptToHead() {
            const isDymoScriptAddedToHead = document.getElementById(
                'upstep-dymo-script'
            );

            if (isDymoScriptAddedToHead) {
                return;
            }

            const script = document.createElement('script');
            script.setAttribute(
                'src',
                `https://s3.amazonaws.com/download.dymo.com/dymo/Software/JavaScript/dymo.connect.framework.js`
            );
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('async', 'async');
            script.setAttribute('id', 'upstep-dymo-script');

            document.head.appendChild(script);
        },

        dymoPrintLabel(
            orderProductColor,
            labelType,
            withBarcode = false
        ) {
            if (!window.dymo) {
                return;
            }

            const printers = window.dymo.label.framework.getPrinters();

            if (!printers.length) {
                return this.$okModal('No DYMO printers are installed.', {
                    title: 'Install DYMO printer'
                });
            }

            const { name: printerName, isConnected } = printers[0];

            if (!isConnected) {
                return this.$okModal('DYMO printer is not connected.', {
                    title: 'Connect DYMO printer'
                });
            }

            if (['both', 'order'].includes(labelType)) {
                const data = {
                    customerName: `${orderProductColor.order.customer.first_name} ${
                        orderProductColor.order.customer.last_name
                    }`,
                    orderNumber: orderProductColor.order.order_number || orderProductColor.order.magento_order_number,
                    productName: orderProductColor.productColor.product.name,
                    gender: orderProductColor.account.genderIdentifier === 'gender_men' ? 'Man' : 'Woman',
                    size: orderProductColor.size,
                    barcodeText: orderProductColor.order.order_number
                }

                const orderLabelXml = withBarcode
                ? getOrderBarcodeLabel(data)
                : getOrderLabel(data);

                const printParamsXml = window.dymo.label.framework.createLabelWriterPrintParamsXml({
                    copies: withBarcode ? 1 : orderProductColor.quantity * 3,
                    twinTurboRoll: 'Right',
                    printQuality: 'Auto'
                });

                window.dymo.label.framework.printLabel(
                    printerName,
                    printParamsXml,
                    orderLabelXml
                );
            }

            if (['both', 'fda'].includes(labelType)) {
                const fdaLabelXml = getFdaLabel({
                    sku: orderProductColor.productColor.product.sku,
                    date: moment().format('MMM DD, YYYY')
                });

                const printParamsXml = window.dymo.label.framework.createLabelWriterPrintParamsXml({
                    copies: orderProductColor.quantity,
                    twinTurboRoll: 'Right',
                    printQuality: 'Auto'
                });

                window.dymo.label.framework.printLabel(
                    printerName,
                    printParamsXml,
                    fdaLabelXml
                );
            }
        }
    }
};

export default (data = {}) => {
    return `<?xml version="1.0" encoding="utf-8"?>
        <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
            <PaperOrientation>Landscape</PaperOrientation>
            <Id>LW_DURABLE_25X89mm</Id>
            <IsOutlined>false</IsOutlined>
            <PaperName>1933081 Drbl 1 x 3-1/2 in</PaperName>
            <DrawCommands>
                <RoundRectangle X="0" Y="0" Width="1440" Height="5040" Rx="90.70866" Ry="90.70866" />
            </DrawCommands>
            <ObjectInfo>
                <TextObject>
                    <Name>TEXT</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">Name:</String>
                            <Attributes>
                                <Font Family="Helvetica" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="330" Y="132" Width="710" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>CUSTOMER_NAME</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">${data.customerName}</String>
                            <Attributes>
                                <Font Family="Arial" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="1490" Y="132" Width="3217.031" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>TEXT_2</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">Model:</String>
                            <Attributes>
                                <Font Family="Helvetica" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="330" Y="375" Width="620" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>MODEL_NAME</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">${data.productName}</String>
                            <Attributes>
                                <Font Family="Arial" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="1490" Y="375" Width="3172.031" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>TEXT_1</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">Order:</String>
                            <Attributes>
                                <Font Family="Helvetica" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="330" Y="618" Width="695" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>ORDER_NUMBER</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">${data.orderNumber}</String>
                            <Attributes>
                                <Font Family="Arial" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="1490" Y="618" Width="3109.375" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>Gender</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">Gender:</String>
                            <Attributes>
                                <Font Family="Helvetica" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="330" Y="861" Width="635" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>PRODUCT_GENDER</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">${data.gender}</String>
                            <Attributes>
                                <Font Family="Arial" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="1490" Y="861" Width="3086.875" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>TEXT__SIZE</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">Size:</String>
                            <Attributes>
                                <Font Family="Helvetica" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="330" Y="1104" Width="605" Height="210" />
            </ObjectInfo>
            <ObjectInfo>
                <TextObject>
                    <Name>PRODUCT_SIZE</Name>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                    <LinkedObjectName />
                    <Rotation>Rotation0</Rotation>
                    <IsMirrored>False</IsMirrored>
                    <IsVariable>False</IsVariable>
                    <GroupID>-1</GroupID>
                    <IsOutlined>False</IsOutlined>
                    <HorizontalAlignment>Left</HorizontalAlignment>
                    <VerticalAlignment>Middle</VerticalAlignment>
                    <TextFitMode>ShrinkToFit</TextFitMode>
                    <UseFullFontHeight>True</UseFullFontHeight>
                    <Verticalized>False</Verticalized>
                    <StyledText>
                        <Element>
                            <String xml:space="preserve">${data.size}</String>
                            <Attributes>
                                <Font Family="Arial" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
                            </Attributes>
                        </Element>
                    </StyledText>
                </TextObject>
                <Bounds X="1490" Y="1104" Width="3109.375" Height="210" />
            </ObjectInfo>
        </DieCutLabel>
    `;
};
